import React from 'react';
import { checkDeps, useDeepCompareMemoize } from "./useDeepCompareMemoize.js";
/**
 * `useDeepCompareEffect` will return a memoized version of the callback that
 * only changes if one of the `deps` has changed.
 *
 * Usage note: only use this if `deps` are objects or arrays that contain
 * objects. Otherwise you should just use React.useEffect.
 *
 */

function useDeepCompareCallback(callback, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies, 'useDeepCompareCallback');
  }

  return React.useCallback(callback, useDeepCompareMemoize(dependencies));
}

export default useDeepCompareCallback;